body {
  margin: 0;
  width: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @include max-w {
    background-image: linear-gradient(to right, rgb(40, 40, 40), black, rgb(40, 40, 40));
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.relative {
  position: relative;
}

.App {
  display: flex;
  justify-content: center; }

.page {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: $max-width;
  overflow-x: hidden; // This caused the wierdass small width on mobile.

  @include max-w {
    border-left: 3px solid white;
    border-right: 3px solid white;
    border-bottom: 3px solid white;
  }
}

.text-link {
  color: white;
  font-style: italic;
  display: inline;
}

.text-link:hover {
  color: #97dcf5;
}

.text-link:active {
  color: #50c8ff;
}